<template>
  <div id="TopInfluencer" @mouseleave="onMouseLeave">
    <div class="overview-header">
      <div class="header-mobile">
        <h4 class="section-title-mobile">Top Influencer</h4>
        <div v-if="!isMobileScreen" class="filter-influencer-mobile">
          <div class="filter-select btn-mobile">
            <a-radio-group v-model:value="selectViews" button-style="solid" class="typeBtn" @change="viewChange">
              <a-radio-button value="grid"><FeatherIcon type="grid" /></a-radio-button>
              <a-radio-button value="list"><FeatherIcon type="list" /></a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div v-if="isMobileScreen" class="filter-influencer-mobile engagement-mobile">
          <div class="filter-select first">
            <div class="type-influencer-container">
              <FeatherIcon class="tag-icon" type="tag" size="15" color="#9299B8" />
              <a-select
                ref="filterSelect"
                class="item-influencer item-type"
                :placeholder="lengthInterest"
                :open="setOpenSelect"
                :get-popup-container="(trigger) => trigger.parentNode"
                @focus="focus"
                @click="onClickSelect"
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <div @mouseleave="onMouseLeave">
                    <div class="section-search">
                      <a-input-search class="input-influencer" placeholder="Search" @keyup="handleSearch($event)">
                      </a-input-search>
                    </div>
                    <div class="section-list">
                      <a-checkbox
                        v-model:checked="checkAll"
                        :indeterminate="indeterminate"
                        class="select-all-checkbox"
                        @change="onCheckAllChange"
                      >
                        <b class="select-all">Select All</b>
                      </a-checkbox>
                      <a-checkbox-group
                        v-if="dataFilters.length > 0"
                        :key="filterOptions"
                        v-model:value="checkedList"
                        :options="dataFilters"
                        class="select-child"
                        @change="onChangeValue"
                      />
                      <span v-if="dataFilters.length === 0" class="content-noresult">No Results Found</span>
                    </div>
                    <div class="section-filter">
                      <a-button class="btn-clear" @click="clearSelect">Clear selection</a-button>
                      <a-button class="btn-apply" :disabled="disableBtn" @click="submitCheckbox">Apply</a-button>
                    </div>
                  </div>
                </template>
              </a-select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobileScreen" class="header-mobile">
        <div class="filter-influencer-mobile engagement-mobile">
          <div class="filter-select first">
            <span class="text-header">Filter:</span>
            <div class="type-influencer-container">
              <FeatherIcon class="tag-icon" type="tag" size="15" color="#9299B8" />
              <a-select
                ref="filterSelect"
                class="item-influencer item-type"
                :placeholder="lengthInterest"
                :open="setOpenSelect"
                :get-popup-container="(trigger) => trigger.parentNode"
                @focus="focus"
                @click="onClickSelect"
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <div @mouseleave="onMouseLeave">
                    <div class="section-search">
                      <a-input-search class="input-influencer" placeholder="Search" @keyup="handleSearch($event)">
                      </a-input-search>
                    </div>
                    <div class="section-list">
                      <a-checkbox
                        v-model:checked="checkAll"
                        :indeterminate="indeterminate"
                        class="select-all-checkbox"
                        @change="onCheckAllChange"
                      >
                        <b class="select-all">Select All</b>
                      </a-checkbox>
                      <a-checkbox-group
                        v-if="dataFilters.length > 0"
                        :key="filterOptions"
                        v-model:value="checkedList"
                        :options="dataFilters"
                        class="select-child"
                        @change="onChangeValue"
                      />
                      <span v-if="dataFilters.length === 0" class="content-noresult">No Results Found</span>
                    </div>
                    <div class="section-filter">
                      <a-button class="btn-clear" @click="clearSelect">Clear selection</a-button>
                      <a-button class="btn-apply" :disabled="disableBtn" @click="submitCheckbox">Apply</a-button>
                    </div>
                  </div>
                </template>
              </a-select>
            </div>
          </div>
        </div>
      </div>
      <div class="header-tablet">
        <div class="filter-influencer-tablet first-tablet">
          <div class="section-title">Top Influencer</div>
          <div v-if="!isMobileScreen" class="filter-select btn-tablet">
            <a-radio-group v-model:value="selectViews" button-style="solid" class="typeBtn" @change="viewChange">
              <a-radio-button value="grid"><FeatherIcon type="grid" /></a-radio-button>
              <a-radio-button value="list"><FeatherIcon type="list" /></a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div class="filter-influencer-tablet">
          <div class="filter-select first">
            <span class="text-header">Filter:</span>
            <div class="type-influencer-container">
              <FeatherIcon class="tag-icon" type="tag" size="15" color="#9299B8" />
              <a-select
                ref="filterSelect"
                class="item-influencer item-type"
                :placeholder="lengthInterest"
                :open="setOpenSelect"
                :get-popup-container="(trigger) => trigger.parentNode"
                @focus="focus"
                @click="onClickSelect"
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <div @mouseleave="onMouseLeave">
                    <div class="section-search">
                      <a-input-search class="input-influencer" placeholder="Search" @keyup="handleSearch($event)">
                      </a-input-search>
                    </div>
                    <div class="section-list">
                      <a-checkbox
                        v-model:checked="checkAll"
                        :indeterminate="indeterminate"
                        class="select-all-checkbox"
                        @change="onCheckAllChange"
                      >
                        <b class="select-all">Select All</b>
                      </a-checkbox>
                      <a-checkbox-group
                        v-if="dataFilters.length > 0"
                        :key="filterOptions"
                        v-model:value="checkedList"
                        :options="dataFilters"
                        class="select-child"
                        @change="onChangeValue"
                      />
                      <span v-if="dataFilters.length === 0" class="content-noresult">No Results Found</span>
                    </div>
                    <div class="section-filter">
                      <a-button class="btn-clear" @click="clearSelect">Clear selection</a-button>
                      <a-button class="btn-apply" :disabled="disableBtn" @click="submitCheckbox">Apply</a-button>
                    </div>
                  </div>
                </template>
              </a-select>
            </div>
          </div>
        </div>
      </div>
      <div class="header-desktop">
        <div class="section-title">Top Influencer</div>
        <div class="filter-influencer">
          <div class="filter-select first">
            <span class="text-header">Filter:</span>
            <div class="type-influencer-container">
              <FeatherIcon class="tag-icon" type="tag" size="15" color="#9299B8" />
              <a-select
                ref="filterSelect"
                class="item-influencer item-type"
                :placeholder="lengthInterest"
                :open="setOpenSelect"
                :get-popup-container="(trigger) => trigger.parentNode"
                @focus="focus"
                @click="onClickSelect"
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <div @mouseleave="onMouseLeave">
                    <div class="section-search">
                      <a-input-search class="input-influencer" placeholder="Search" @keyup="handleSearch($event)">
                      </a-input-search>
                    </div>
                    <div class="section-list">
                      <a-checkbox
                        v-model:checked="checkAll"
                        :indeterminate="indeterminate"
                        class="select-all-checkbox"
                        @change="onCheckAllChange"
                      >
                        <b class="select-all">Select All</b>
                      </a-checkbox>
                      <a-checkbox-group
                        v-if="dataFilters.length > 0"
                        :key="filterOptions"
                        v-model:value="checkedList"
                        :options="dataFilters"
                        class="select-child"
                        @change="onChangeValue"
                      />
                      <span v-if="dataFilters.length === 0" class="content-noresult">No Results Found</span>
                    </div>
                    <div class="section-filter">
                      <a-button class="btn-clear" @click="clearSelect">Clear selection</a-button>
                      <a-button class="btn-apply" :disabled="disableBtn" @click="submitCheckbox">Apply</a-button>
                    </div>
                  </div>
                </template>
              </a-select>
            </div>
          </div>
          <div v-if="!isMobileScreen" class="filter-select btn-desktop" @mouseleave="onMouseLeave">
            <a-radio-group v-model:value="selectViews" button-style="solid" class="typeBtn" @change="viewChange">
              <a-radio-button value="grid"><FeatherIcon type="grid" /></a-radio-button>
              <a-radio-button value="list"><FeatherIcon type="list" /></a-radio-button>
            </a-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div v-if="influencerLoading" class="text-center pa-4">
      <a-spin />
    </div>
    <InfluencerGrids
      v-if="!influencerLoading && influencerList.length !== 0 && selectViews === 'grid' && !isMobileScreen"
      :influencerDataList="influencerList"
      :sortBy="sortBy"
      :filterResult="filter"
    ></InfluencerGrids>
    <TopInfluencerLists
      v-if="!influencerLoading && influencerList.length !== 0 && (selectViews === 'list' || isMobileScreen)"
      :influencerDataList="influencerList"
      :sortBy="sortBy"
      :filterResult="filter"
    ></TopInfluencerLists>
    <div v-if="!influencerLoading && influencerList.length === 0" class="load-wrapper">
      <NoDataFound></NoDataFound>
    </div>
  </div>
</template>
<script>
import { ref, toRefs, watch, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import TopInfluencerLists from '@/components/Lists/TopInfluencerLists.vue';
import InfluencerGrids from '@/components/Grids/InfluencerGrids.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
export default {
  name: 'FilterTopInfluencer',
  components: {
    TopInfluencerLists,
    NoDataFound,
    InfluencerGrids,
  },
  props: {
    filter: Object,
    chartData: Object,
    loading: Boolean,
    typeFilter: Array,
    sortBy: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { filter: filterCriteria, loading: topInfluencerLoading, chartData, typeFilter } = toRefs(props);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const selectViews = ref('grid');
    const influencerLoading = computed(() => topInfluencerLoading.value);
    const influencerList = ref([]);
    const lengthInterest = ref('Interests');
    const isInit = ref(false);
    const searchValue = ref('');
    let valueMatch = ref('');
    let filterOptions = ref(typeFilter.value);
    let dataFilters = ref(typeFilter.value);
    const filterSelect = ref(null);
    const disableBtn = ref(true);
    const setOpenSelect = ref(false);
    const state = reactive({
      indeterminate: false,
      checkAll: true,
      checkedList: filterOptions.value,
    });
    let influencerFullData = ref([]);

    const init = () => {
      if (chartData.value && chartData.value !== null) {
        const { influencers, peopleInfo } = chartData.value;
        let finalResult = [];
        for (let peopleObj of influencers) {
          const info = peopleInfo[peopleObj.id];
          let totalMention = 0;
          let mention = {};
          for (let a of peopleObj.actions) {
            mention[a.key] = helper.numeral(a.value, '0,0', false, null);
            totalMention += a.value;
          }
          // let totalEngage = 0;
          let totalEngage = peopleObj.total_engagement;
          let engagement = {};
          for (let a of peopleObj.reactions) {
            engagement[a.key] = helper.numeral(a.value, '0,0', false, null);
            // totalEngage += a.value;
          }
          const newProfileImage = info.user_photo;
          const fallbackImage = info.fallback_user_photo;
          let types = [];
          const tooltip = [];
          const showTag = [];
          let moreItem = '';
          if (info.interests.length !== 0) {
            if (state.indeterminate) {
              for (let cat of info.interests) {
                for (let check of state.checkedList) {
                  if (cat.interest_id === check) {
                    types.push(cat.interest_name);
                  }
                }
              }
              if (types.length === state.checkedList.length) {
                if (types.length >= 2) {
                  let strLength = 0;
                  for (let index in types) {
                    strLength += types[index].length;
                    if (strLength >= 40) {
                      tooltip.push(types[index]);
                    } else {
                      showTag.push(types[index]);
                    }
                  }
                  moreItem = '+ ' + tooltip.length;
                }
                finalResult.push({
                  ...peopleObj,
                  info,
                  totalMention: helper.numeral(totalMention),
                  totalEngage: helper.numeral(totalEngage),
                  sentiment: {
                    positive: helper.numeral(peopleObj.sentiment_count[2].value),
                    neutral: helper.numeral(peopleObj.sentiment_count[1].value),
                    negative: helper.numeral(peopleObj.sentiment_count[0].value),
                  },
                  mention,
                  engagement,
                  newProfileImage,
                  fallbackImage,
                  types,
                  tooltip,
                  showTag,
                  moreItem,
                });
              }
            } else {
              for (let cat of info.interests) {
                types.push(cat.interest_name);
              }
              if (types.length >= 2) {
                let strLength = 0;
                for (let index in types) {
                  strLength += types[index].length;
                  if (strLength >= 40) {
                    tooltip.push(types[index]);
                  } else {
                    showTag.push(types[index]);
                  }
                }
                moreItem = '+ ' + tooltip.length;
              }
              finalResult.push({
                ...peopleObj,
                info,
                totalMention: helper.numeral(totalMention),
                totalEngage: helper.numeral(totalEngage),
                sentiment: {
                  positive: helper.numeral(peopleObj.sentiment_count[2].value),
                  neutral: helper.numeral(peopleObj.sentiment_count[1].value),
                  negative: helper.numeral(peopleObj.sentiment_count[0].value),
                },
                mention,
                engagement,
                newProfileImage,
                fallbackImage,
                types,
                tooltip,
                showTag,
                moreItem,
              });
            }
          } else if (info.interests.length === 0 && (state.checkAll || !state.indeterminate)) {
            finalResult.push({
              ...peopleObj,
              info,
              totalMention: helper.numeral(totalMention),
              totalEngage: helper.numeral(totalEngage),
              sentiment: {
                positive: helper.numeral(peopleObj.sentiment_count[2].value),
                neutral: helper.numeral(peopleObj.sentiment_count[1].value),
                negative: helper.numeral(peopleObj.sentiment_count[0].value),
              },
              mention,
              engagement,
              newProfileImage,
              fallbackImage,
              types,
              tooltip,
              showTag,
              moreItem,
            });
          }
        }
        influencerFullData.value = finalResult;
        influencerList.value = finalResult;
      }
      influencerLoading.value = false;
      isInit.value = true;
    };

    const filterInterest = () => {
      influencerList.value = [];
      if (state.indeterminate) {
        for (let influencer of influencerFullData.value) {
          for (let check of state.checkedList) {
            for (let type of influencer.types) {
              if (type.toLowerCase() === check) {
                if (influencerList.value.length === 0) {
                  influencerList.value.push(influencer);
                } else {
                  const isInflu = influencerList.value.find(
                    (x) => x.id === influencer.id && x.types.find((t) => t !== check)
                  );
                  if (!isInflu) {
                    influencerList.value.push(influencer);
                  }
                }
              }
            }
          }
        }
      } else {
        influencerList.value = influencerFullData.value;
      }

      emit('setLoading', false);
    };

    const filterChange = (value) => {
      influencerLoading.value = true;
      console.log(`selected ${value}`);
    };

    const onCheckAllChange = (e) => {
      Object.assign(state, {
        checkedList: e.target.checked ? dataFilters.value : [],
        indeterminate: false,
      });
      disableBtn.value = false;
    };

    const onChangeValue = () => {
      disableBtn.value = false;
    };

    const clearSelect = () => {
      state.checkedList = [];
      state.indeterminate = false;
    };

    const onClickSelect = () => {
      setOpenSelect.value = true;
    };

    const onMouseLeave = () => {
      setOpenSelect.value = false;
    };

    const handleSearch = (e) => {
      dataFilters.value = filterOptions.value.filter((text) =>
        text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      return dataFilters.value;
    };

    const submitCheckbox = async () => {
      emit('setLoading', true);
      await filterInterest();
    };

    watch(
      () => state.checkedList,
      (val) => {
        state.indeterminate = !!val.length && val.length < dataFilters.value.length;
        state.checkAll = val.length === dataFilters.value.length;
        lengthInterest.value = 'Interests (' + val.length + ')';
      }
    );

    watch(
      () => typeFilter.value,
      () => {
        filterOptions.value = typeFilter.value;
        dataFilters.value = typeFilter.value;
        state.checkedList = filterOptions.value;
        init();
      }
    );
    watch(
      () => filterCriteria.value,
      () => {
        init();
      }
    );

    watch(
      () => chartData.value,
      () => {
        init();
        if (filterOptions.value.length !== 0) {
          lengthInterest.value = 'Interests (' + filterOptions.value.length + ')';
        }
      }
    );

    onMounted(() => {
      dataFilters.value = filterOptions.value;
    });

    const viewChange = (value) => {
      selectViews.value = value.target.value;
      emit('onViews', selectViews.value);
    };

    return {
      ...toRefs(state),
      selectViews,
      filterChange,
      influencerList,
      viewChange,
      influencerLoading,
      filterOptions,
      onCheckAllChange,
      lengthInterest,
      clearSelect,
      handleSearch,
      searchValue,
      valueMatch,
      dataFilters,
      submitCheckbox,
      filterSelect,
      isMobileScreen,
      disableBtn,
      onChangeValue,
      setOpenSelect,
      onClickSelect,
      onMouseLeave,
    };
  },
};
</script>
<style lang="scss" scoped>
#TopInfluencer {
  // overflow: auto;
  padding: 0px 0 20px;
  .overview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0px;
    @media (max-width: 1160px) {
      display: block;
    }
    .header-desktop {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1160px) {
        display: none;
      }
    }
    .header-tablet {
      display: none;
      @media (max-width: 1160px) {
        display: block;
        .filter-influencer-tablet {
          display: flex;
          justify-content: flex-end;
          .show-filter-desktop {
            @media (max-width: 1160px) {
              display: none;
            }
            @media (max-width: 991px) {
              display: flex;
            }
            @media (max-width: 874px) {
              display: none;
            }
          }
        }
        .showBy-tablet {
          @media (max-width: 1160px) {
            display: flex;
            margin-top: 20px;
          }
          @media (max-width: 991px) {
            display: none;
          }
          @media (max-width: 874px) {
            display: flex;
            margin-top: 20px;
          }
        }
        .first-tablet {
          margin-bottom: 20px;
          justify-content: space-between;
        }
      }
      @media (max-width: 750px) {
        display: none;
      }
    }
    .header-mobile {
      display: none;
      @media (max-width: 750px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .section-title-mobile {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
      }
      .filter-influencer-mobile {
        display: flex;
        .show-filter-mobile {
          padding: 0;
        }
        .btn-mobile {
          padding-right: 15px;
          @media (max-width: 750px) {
            padding: 0;
          }
        }
      }
      .engagement-mobile {
        margin-left: auto;
        justify-content: flex-end;
        @media (max-width: 468px) {
          width: 100%;
          margin-left: 0;
          justify-content: space-between;
        }
      }
    }
  }
  .section-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    @media (max-width: 1295px) {
      margin-bottom: 20px;
    }
    @media (max-width: 1160px) {
      margin-bottom: 0;
    }
  }
  .filter-influencer {
    display: flex;
    align-items: center;
    color: #5a5f7d;
    .btn-desktop {
      @media (max-width: 1160px) {
        display: none;
      }
    }
    .btn-tablet {
      padding: 0 !important;
      margin-left: auto;
      @media (max-width: 1160px) {
        display: flex;
      }
    }
    @media (max-width: 1295px) {
      justify-content: flex-end;
    }
    @media (max-width: 1160px) {
      justify-content: flex-end;
    }
    @media (max-width: 750px) {
      justify-content: space-between;
    }
    .show-filter-desktop {
      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .filter-select {
    padding-left: 15px;
    display: flex;
    align-items: center;
    .typeBtn {
      display: flex;
    }
    &.first {
      padding-left: 0;
    }
    .type-influencer-container {
      padding: 0;
      position: relative;
      border-radius: 4px;
      background: #ffffff;
      border: none;
      height: 40px;
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        left: 12px;
      }
      .sort-img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        left: 12px;
      }
    }
  }

  .text-header {
    margin-right: 8px;
    font-size: 14px;
    @media (max-width: 750px) {
      display: none;
    }
  }

  .section-title {
    font-size: 18px;
  }
  .item-influencer {
    position: relative;
    color: #5a5f7d;
    min-width: 150px;
    margin: 0;
    @media (max-width: 750px) {
      min-width: fit-content;
    }
    @media (max-width: 468px) {
      min-width: 173px;
    }
    @media (max-width: 418px) {
      min-width: 123px;
    }
    @media (max-width: 354px) {
      min-width: 103px;
    }
  }
  .item-type {
    min-width: 250px;
    @media (max-width: 468px) {
      min-width: 166px;
    }
    @media (max-width: 418px) {
      min-width: 136px;
    }
    @media (max-width: 354px) {
      min-width: 103px;
    }
  }
}
.section-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-apply {
  background-color: #20c997;
  color: white;
  font-size: 12px;
  height: 30px;
}
.btn-clear {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 0 15px 0 0;
}
.icon-search {
  color: #9299b8;
}

.input-influencer {
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
}

.section-search {
  padding: 0 0 8px;
  border-bottom: 1px solid #f1f2f6;
}

.section-filter {
  padding: 8px 8px 0;
  border-top: 1px solid #f1f2f6;
}

.section-list {
  display: grid;
  padding: 8px 0;
  .select-all-checkbox {
    padding: 0 8px;
    .select-all {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.content-noresult {
  text-align: center;
  color: #5a5f7d;
  margin-top: 10px;
  background-color: rgba(244, 245, 247, 0.5);
  padding: 12px 8px;
}

.hidepopup {
  display: none;
}
</style>

<style lang="scss">
.item-influencer.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.item-influencer .ant-select-selector,
.item-influencer .ant-select-item-option-content {
  text-transform: capitalize;
}
.item-influencer.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-left: 19px;
}
.item-sort.ant-select-single .ant-select-selector .ant-select-selection-item {
  padding-left: 19px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.filter-select .ant-radio-button {
  margin-top: 4px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #3371ff;
  border-color: #3371ff;
}

.ant-radio-button-wrapper:hover {
  color: #3371ff;
}

.ant-select-dropdown-empty {
  width: 250px !important;
  min-width: 250px !important;
}

.pd-select .ant-select-dropdown .ant-select-dropdown-content div {
  padding: 2px;
}

.select-child {
  padding: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #3371ff;
  border-color: #3371ff;
}
.select-child.ant-checkbox-group {
  margin-top: 8px;
  height: 200px;
  overflow-y: scroll;
  background: rgba(244, 245, 247, 0.5);
}
.ant-select-dropdown {
  padding: unset !important;
}

.ant-select-selection-placeholder {
  color: #5a5f7d;
  line-height: 36px !important;
}

.ant-checkbox + span {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.select-child.ant-checkbox-group .ant-checkbox-group-item {
  margin-bottom: 8px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style scoped>
:deep(#sort-selector .ant-select-selector),
:deep(#count-selector .ant-select-selector),
:deep(#alert-review-selector .ant-select-selector),
:deep(#level-select .ant-select-selector) {
  border: none !important;
  height: 34px;
}
:deep(.ant-select-dropdown) {
  height: auto;
  min-height: 200px;
}
:deep(.select-child.ant-checkbox-group) {
  height: auto;
}
</style>
