<template>
  <a-card id="TypeInfluencer" :widget="true" :title="title">
    <div v-if="chartLoading" class="load-wrapper">
      <a-skeleton :loading="chartLoading" active />
    </div>
    <span v-if="!chartLoading" class="influencer-total">
      <span class="total-title">Total:</span>
      <span class="total">{{ influencerCount + ' influencers' }}</span>
    </span>
    <div v-if="!chartLoading && selectedData.length > 0" class="chart-wrapper">
      <EChart
        :type="'pie'"
        :data="selectedData"
        :doughnut-chart="false"
        :chart-type="type"
        @chartClick="onChartClick"
      />
    </div>
    <div v-if="!chartLoading && selectedData.length <= 0" class="no-data-wrapper">
      <NoResult></NoResult>
    </div>
  </a-card>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import EChart from '@/components/Chart/Echart.vue';
import NoResult from '@/components/Error/NoResult.vue';
export default {
  name: 'TypeInfluencer',
  components: {
    NoResult,
    EChart,
  },
  props: {
    filter: Object,
    influencerChartData: Array,
    title: String,
    chartType: String,
    loading: Boolean,
  },
  setup(props) {
    const { filter, loading, influencerChartData, chartType } = toRefs(props);
    const { dispatch } = useStore();
    const type = ref('influencer');
    const totalCount = ref(0);

    let chartLoading = ref(loading.value);

    const selectedData = ref([]);
    const influencerCount = ref(0);

    const setStringName = (name) => {
      let result = '';
      const str = name.toLowerCase();
      if (str.indexOf('_') > -1) {
        let splitted = str.split('_');
        if (splitted.length >= 2) {
          for (let index in splitted) {
            if (index === 0) {
              result += helper.capitalize(splitted[index]);
            } else if (index === splitted.length - 1) {
              result += helper.capitalize(splitted[index]);
            } else {
              result += ' ' + helper.capitalize(splitted[index]);
            }
          }
        }
      } else {
        result = helper.capitalize(str);
      }
      return result;
    };

    const getInfluencerType = () => {
      let influencerType = null;

      if (influencerChartData.value && influencerChartData.value != null) {
        influencerCount.value = influencerChartData.value.relatedInfluencerCount
        influencerType = influencerChartData.value.chartData;
      }

      let lists = [];

      if (influencerType !== null) {
        let visibleDataTotal = 0;
        let visibleData = [];

        for (const influencer of influencerType) {
          visibleDataTotal += influencer.count;
          visibleData.push({
            name: setStringName(influencer.name),
            value: influencer.count,
            percent: influencer.percent.toString(),
          });
        }

        totalCount.value = visibleDataTotal;

        if (visibleData && visibleData.length) {
          for (let index in visibleData) {
            const color = getColor(false, index);
            lists.push({
              name: visibleData[index].name,
              value: visibleData[index].value,
              valueAsPercent: visibleData[index].percent,
              itemStyle: {
                color: color,
              },
            });
          }
        }
      }

      selectedData.value = lists;

      chartLoading.value = false;
    };

    const getColor = (same, index) => {
      const defaultColors = ['#2C99FF', '#FF87B7', '#4DD4AC', '#FFBD59', '#5F63F2'];
      if (index <= 4) {
        return defaultColors[index];
      } else {
        let lastColor;
        var rgb = [];
        if (same && lastColor) {
          rgb = lastColor;
        } else {
          rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
          var mix = [rgb[0] / 50, rgb[1] / 50, rgb[2] / 50]; //51 => 255/5
          var mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function (x) {
            return Math.round(x);
          });
          lastColor = rgb;
          return 'rgb(' + mixedrgb.join(',') + ')';
        }
      }
    };

    // Modal
    const onChartClick = (value) => {
      if (value) {
        const type = value.data.name;
        const userId = [];
        const { influencers, peopleInfo } = influencerChartData.value;
        for (let peopleObj of influencers) {
          const info = peopleInfo[peopleObj.id];
          if (chartType.value === 'type') {
            if (setStringName(info.type) === type) {
              userId.push(peopleObj.id);
            }
          } else if (chartType.value === 'audience_size') {
            if (setStringName(info.audience_size) === type) {
              userId.push(peopleObj.id);
            }
          }
        }
        const newFilter = JSON.parse(JSON.stringify(filter.value));
        let f = {
          payload: {
            title: `Messages from "${type}"`,
          },
          criteria: {
            ...newFilter,
            userId,
            sort: {
              direction: 'desc',
              type: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
          type: 'influencer',
        };

        dispatch('message/showMessageModal', f);
      }
    };

    // INIT part
    // const init = (filterValue) => {
    const init = async () => {
      await getInfluencerType();
    };

    watch(loading, () => {
      chartLoading.value = loading.value;
    });

    watch(
      () => filter.value,
      () => {
        init();
      }
    );

    watch(
      () => influencerChartData.value,
      () => {
        init();
      }
    );

    return {
      chartLoading,
      selectedData,
      onChartClick,
      type,
      totalCount,
      influencerCount,
    };
  },
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';
#TypeInfluencer {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }
  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;
    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .industry-representation-selector-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
  }
  @media screen and (max-width: 560px) {
    .industry-representation-selector-wrapper {
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .industry-accumulation-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 16px;
    column-gap: 16px;
    .industry-mode-toggle {
      .ant-dropdown-trigger {
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .loading-wrapper {
    min-height: 350px;
  }

  .no-data-wrapper {
    min-height: 400px;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
    display: block;
  }
  @media screen and (max-width: 560px) {
    .ant-card-head-wrapper .ant-card-extra {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      column-gap: 12px;
    }
  }
  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 200px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0;
    }
  }

  .chart-type {
    width: fit-content;
    margin-left: auto;
    padding: 15px 0;
    line-height: 1.4;
  }

  @media screen and (max-width: 560px) {
    .chart-type {
      margin-left: 0;
      justify-content: flex-end;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }
  .influencer-total {
    font-size: 12px;
    line-height: 16px;
    .total-title {
      color: #9299b8;
      padding-right: 4px;
    }
    .total {
      color: #5a5f7d;
    }
  }
  .chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    x-vue-echarts {
      display: flex !important;
      height: 400px !important;
    }
  }
}
</style>
